import React, { FunctionComponent } from 'react';
import { generateCloudinaryUrl } from '../../../helpers/cloudinary-helper/cloudinary-helper';
import { PromoBannerVariationOptions } from '../../../helpers/recommendations/recommendations.helper';
import { StyledButton } from '../../buttons';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { DoorMarker } from '../promo-helpers/door-marker.component';
import { defaultCouponExpirationString } from '../promo-helpers/promo-banner.helpers';

export const SecondaryPromoE: FunctionComponent<PromoBannerVariationOptions> = ({
	headline,
	subHeading,
	couponCode,
	expirationDate,
	action,
	image,
	imageOverlay
}) => {
	const backgroundUrl = generateCloudinaryUrl(image.id, {
		width: 1000,
		height: undefined,
		gravity: 'custom',
		zoom: '0.9',
		crop: 'thumb'
	});

	const backgroundStyle = {
		background: `url(${backgroundUrl}) no-repeat center center`,
		backgroundSize: 'cover',
		boxShadow: 'inset 0 0 0 1000px rgba(255, 255, 255, 0.7)'
	};

	return (
		<div className="ba b--theme-grey-light pa3" style={backgroundStyle}>
			<div className="b f4 theme-primary">{headline}</div>
			<div className="flex mv3">
				<div className="pr2 w-50">
					<div className="f6 lh-title theme-grey-darker">{subHeading}</div>
					{(couponCode || expirationDate) && (
						<div className="f7 mv2 theme-grey-darker lh-copy">
							<DoorMarker />
							{defaultCouponExpirationString(couponCode, expirationDate)}
						</div>
					)}
				</div>

				<div className="pl2 relative w-50 flex items-center justify-center">
					{imageOverlay && (
						<CloudinaryImage
							publicID={imageOverlay.id}
							description="promo"
							className="bottom-0 left-0 max-h-100 pa3"
							options={{
								width: 80,
								height: undefined,
								crop: 'lfill',
								gravity: 'custom',
								preserveTransparency: true
							}}
							useDimensions={false}
						/>
					)}
				</div>
			</div>
			{action && (
				<div className="w-100">
					<StyledButton size="LARGE">{action.name}</StyledButton>
				</div>
			)}
		</div>
	);
};
